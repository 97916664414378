import { Divider, Form, Input, Modal, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { ProviderSelect } from "../../../components/ProviderSelect";
import useCredentialsApi from "../../../services/reportGateway/api/credentialsApi";
import { CertificateSelect } from "../../../components/Certificates";
import { OrganizationSelect } from "../../Organizations";

const { useForm } = Form;
const { TextArea } = Input;

const SignCertificateId = 'SignCertificateId';
const EncryptCertificateId = 'EncryptCertificateId';

interface IBackendForm {
  Description: string;
  OrganizationId: string;
  ProviderId: string;
  [SignCertificateId]: string;
  [EncryptCertificateId]: string;
  Value: string;
}

interface ICredentialsAdditionProps {
  providerId?: string
  open: boolean;
  onCancel(): void;
  onOk(): void;
}

export function CredentialsAddition(props: ICredentialsAdditionProps) {
  const { providerId, open, onCancel, onOk } = props;

  const credentialsApi = useCredentialsApi();

  const [form] = useForm<IBackendForm>();

  const [isProcessing, setIsProcessing] = useState(false);
  const [signCertificateId, setSignCertificateId] = useState('');
  const [encryptCertificateId, setEncryptCertificateId] = useState('');

  useEffect(() => {
    form.setFieldValue(SignCertificateId, signCertificateId);
  }, [signCertificateId])

  useEffect(() => {
    form.setFieldValue(EncryptCertificateId, encryptCertificateId);
  }, [encryptCertificateId])

  async function handleOnFinish(values: IBackendForm) {
    const { ProviderId, Description, OrganizationId, SignCertificateId, EncryptCertificateId, Value } = values;

    let successful = true;

    setIsProcessing(true);

    try {
      await credentialsApi.create(Description, OrganizationId, SignCertificateId ? SignCertificateId : null,
        EncryptCertificateId ? EncryptCertificateId : null, Value)
    } catch (error) {
      console.error(error);
      successful = false;
    } finally {
      setIsProcessing(false);
    }

    if (successful) {
      onOk();
      form.resetFields();
    } else {
      notification.error({
        message: 'Не удалось добавить эндпоинт'
      })
    }
  }

  function handleOnCancel() {
    onCancel();
    form.resetFields();
  }

  return (
    <Modal
      width={600}
      title='Добавление учетных данных'
      open={open}
      closable={false}
      maskClosable={false}
      onOk={form.submit}
      okButtonProps={{ loading: isProcessing }}
      okText='Добавить'
      onCancel={handleOnCancel}
      cancelButtonProps={{ disabled: isProcessing }}
      cancelText='Отмена'
    >
      <Form
        form={form}
        initialValues={{
          Description: '',
          ProviderId: providerId ?? '',
          OrganizationId: '',
          SignCertificateId: '',
          EncryptCertificateId: '',
          Value: '{}'
        }}
        onFinish={handleOnFinish}
        labelCol={{ span: 6 }}
      >
        <Form.Item
          name='Description'
          label='Описание'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          hidden={providerId !== undefined && providerId !== ''}
          name='ProviderId'
          label='Поставщик'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <ProviderSelect />
        </Form.Item>
        <Form.Item
          name='OrganizationId'
          label='Организация'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <OrganizationSelect />
        </Form.Item>
        <Form.Item
          name='Value'
          label='Значение (json)'
          rules={[
            {
              validator(rule, value) {
                try {
                  const obj = JSON.parse(value);
                  switch (typeof (obj)) {
                    case 'object': return Array.isArray(obj)
                      ? Promise.reject('Is array')
                      : Promise.resolve();
                    default:
                      return Promise.reject('Not object');
                  }
                } catch (error) {
                  return Promise.reject('error');
                }
              },
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <Divider orientation='left'>Подписание</Divider>
        <Form.Item name={SignCertificateId}>
          <CertificateSelect
            providerId={providerId}
            value={signCertificateId}
            onChange={setSignCertificateId}
          />
        </Form.Item>

        <Divider orientation='left'>Шифрование</Divider>
        <Form.Item name={EncryptCertificateId}>
          <CertificateSelect
            providerId={providerId}
            value={encryptCertificateId}
            onChange={setEncryptCertificateId}
          />
        </Form.Item>

      </Form>
    </Modal>
  )
}