import moment, { Moment } from "moment";
import { Alert, DatePicker, Descriptions, Form, Input, Modal, Row, Upload, message, notification } from "antd";
import { InboxOutlined, FileProtectOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import useCertificatesApi from "../../../services/reportGateway/api/certificatesApi";
import { OrganizationSelect } from "../../Organizations";
import { useMutation } from "react-query";

const { useForm } = Form;
const { RangePicker } = DatePicker;
const { Dragger } = Upload;

interface ICertData {
  Thumbprint: string;
  SerialNumber: string;
  Issuer: string;
  Subject: string;
}

interface IBackendForm {
  Description: string;
  OrganizationId: string;
  //ProviderId: string;
  Data: ICertData | null;
  Period: Moment[];
}

interface ICertificateAdditionProps {
  providerId?: string
  open: boolean;
  onCancel(): void;
  onSuccess(): void;
}

const types = [
  'application/x-x509-ca-cert'
];

export function CertificateAddition(props: ICertificateAdditionProps) {
  const { providerId, open, onCancel, onSuccess } = props;

  const api = useCertificatesApi();

  const [form] = useForm<IBackendForm>();

  const { mutate, isLoading } = useMutation(mutationFn, {
    onSuccess: () => {
      notification.success({
        message: 'Сертификат успешно добавлен'
      })
      onSuccess();
      reset();
    },
    onError() {
      notification.error({
        message: 'Не удалось добавить сертификат'
      })
    }
  })

  function mutationFn(values: IBackendForm) {
    const { OrganizationId, Description, Period, Data } = values;
    if (Data === null) return Promise.reject('Certificate data is null');
    if (Period.length !== 2) return Promise.reject('Invalid period');

    return api.create(Description, OrganizationId, Data.Thumbprint, Data.SerialNumber,
      Data.Issuer, Data.Subject, Period[0], Period[1]);
  }

  function handleOnCancel() {
    onCancel();
    reset();
  }

  function reset() {
    setSelected(null);
    form.resetFields();
  }

  const [selected, setSelected] = useState<ICertData | null>(null);

  return (
    <Modal
      width={850}
      title='Добавление сертификата'
      open={open}
      closable={false}
      maskClosable={false}
      destroyOnClose
      onOk={form.submit}
      okButtonProps={{ loading: isLoading }}
      okText='Добавить'
      onCancel={handleOnCancel}
      cancelButtonProps={{ disabled: isLoading }}
      cancelText='Отмена'
    >
      <Form
        form={form}
        initialValues={{
          Description: '',
          ProviderId: providerId ?? '',
          Data: null
        }}
        onFinish={mutate}
        labelCol={{ span: 6 }}
      >
        <Form.Item
          name='Data'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <Dragger
            disabled={isLoading}
            accept='.cer'
            action='/api/certificates/file'
            listType='picture'
            iconRender={() => <FileProtectOutlined />}
            maxCount={1}
            beforeUpload={file => {
              form.setFieldValue('Data', null);
              setSelected(null);

              const isCert = types.indexOf(file.type) >= 0;
              if (!isCert) message.error(`${file.name} не сертификат`);
              return isCert || Upload.LIST_IGNORE;
            }}
            onChange={i => {
              let val: ICertData | null = null;
              let description: string | null = null;
              let period: Moment[] = [];

              if (i.file.status === 'done') {
                const startDate = moment(i.file.response.startDate);
                const endDate = startDate.clone().add(1, 'year');
                period = [startDate, endDate];
                description = i.file.name;
                val = {
                  Thumbprint: i.file.response.thumbprint,
                  SerialNumber: i.file.response.serialNumber,
                  Issuer: i.file.response.issuer,
                  Subject: i.file.response.subject,
                }
              }

              form.setFieldValue('Data', val);
              form.setFieldValue('Description', description);
              form.setFieldValue('Period', period);
              setSelected(val);
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
          </Dragger>
          <Descriptions
            style={{ paddingTop: '1rem' }}
            labelStyle={{ width: '150px' }}
            bordered
            column={1}
            size='small'
          >
            <Descriptions.Item label='Отпечаток'>
              {selected?.Thumbprint ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label='Серийный номер'>
              {selected?.SerialNumber ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label='Издатель'>
              {selected?.Issuer ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label='Владелец'>
              {selected?.Subject ?? '-'}
            </Descriptions.Item>
          </Descriptions>
        </Form.Item>

        <Form.Item
          name='Description'
          label='Описание'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='OrganizationId'
          label='Организация'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <OrganizationSelect />
        </Form.Item>

        {/* <Form.Item
          hidden={providerId !== undefined && providerId !== ''}
          name='ProviderId'
          label='Поставщик'
          rules={[
            { required: true, message: 'Обязательное поле' }
          ]}
        >
          <ProviderSelect />
        </Form.Item> */}

        <Form.Item
          name='Period'
          label='Период'
          rules={[
            { required: true, message: 'Обязательное поле' },
          ]}
        >
          <RangePicker
            style={{ width: '100%' }}
            allowEmpty={[false, false]}
          />
        </Form.Item>

        <Alert
          style={{ paddingTop: '1rem' }}
          type='warning'
          description='Обратите внимание на дату окончания сертификата'
        />

      </Form>
    </Modal>
  )
}