import IOrganizationReferencesItem from "../../../Types/backend/organization/OrganizationReferencesItem";
import { useMakeReportGatewayRequest } from "../makeReportGatewayRequest";

interface IOrganizationReferencesItemResponse {
  id: string;
  name: string;
}

interface IOrganizationsApi {
  references(): Promise<IOrganizationReferencesItem[]>;
}

export default function useOrganizationsApi(): IOrganizationsApi {
  const { makeReportGatewayRequest } = useMakeReportGatewayRequest();

  function references(): Promise<IOrganizationReferencesItem[]> {
    return makeReportGatewayRequest<IOrganizationReferencesItemResponse[], IOrganizationReferencesItem[]>(
      '/api/organizations/references',
      'get',
      response => response,
      null,
      'json'
    );
  }
  return {
    references
  }
}