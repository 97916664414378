import { Select, Skeleton } from "antd";
import React from "react";
import useOrganizationsApi from "../../../services/reportGateway/api/organizationsApi";
import { useQuery } from "react-query";
import { DefaultOptionType, SelectProps } from "antd/lib/select";

export function OrganizationSelect(props: SelectProps) {
  const organizationsApi = useOrganizationsApi();

  const { data: organizations, error, isLoading, isFetching } = useQuery(
    ['organizations', 'references'],
    fetchData,
    { retry: 3 }
  )

  async function fetchData(): Promise<DefaultOptionType[]> {
    const response = await organizationsApi.references();

    return response.map<DefaultOptionType>(value => ({
      label: value.name,
      value: value.id
    }))
  }

  return isLoading ? (
    <Skeleton.Input
      style={{ width: '100%' }}
      active
    />
  ): (
    <Select
      {...props}
      allowClear
      options={organizations}
      loading={isLoading || isFetching}
      status={error ? 'error' : undefined}
    />
  )
}